import React from 'react';
import { Alert, Button } from 'antd';
import { CordovaCommunication } from '../Main/cordovaCommunication'
import * as updates from '../../updates';
import { injectIntl } from "react-intl";

import { Statistic } from 'antd';
import msg from './messages';
import { withRouter } from 'react-router-dom';

const { Countdown } = Statistic;

const A_SECOND = 1000;
const A_MINUTE = A_SECOND * 60;
const AN_HOUR = A_MINUTE * 60;
const RANDOM_MINUTES_WITHIN_HOUR = Math.floor(Math.random() * 60) * A_MINUTE;
const SIX_HOURS = 6 * AN_HOUR;
const SIX_HOURS_AT_RANDOM_MINUTE = SIX_HOURS + RANDOM_MINUTES_WITHIN_HOUR;


class Updates extends React.Component {
  state = { updatesAvailables: false, deadline: false }
  interval = null;
  url_dashboard = `/${this.props.building}/${this.props.unit}/dashboard/`

  componentDidMount() {
    this.interval = setInterval(this.checkForUpdates, SIX_HOURS_AT_RANDOM_MINUTE);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname === this.url_dashboard) {
        this.setState({ deadline: Date.now() + 30 * A_SECOND })
      }
    }
  }

  checkForUpdates = async () => {
    const updatesAvailables = await updates.checkForUpdates();
    this.setState({ updatesAvailables }, () => {
      if (this.state.updatesAvailables) {
        this.setState({ deadline: Date.now() + 30 * A_SECOND });
      }
    });
  }

  runUpdates = async () => {
    CordovaCommunication.sendData(CordovaCommunication.UPDATING);
    await updates.downloadLast();
  }

  reset = () => {
    this.setState({ updatesAvailables: false, deadline: false });
  }

  timeIsOver = () => {
    if (this.props.location.pathname === this.url_dashboard) {
      this.runUpdates();
      this.reset();
    } else {
      this.setState({ deadline: Date.now() + 30 * A_SECOND })
    }
  }

  render = () => {
    const { deadline, updatesAvailables } = this.state;
    const t = this.props.intl.formatMessage

    if (deadline && updatesAvailables) {
      return (
        <div className="updates-banner">
          <Alert
            message={t(msg.updateFound)}
            description={
              <Countdown
                value={deadline}
                onFinish={() => this.timeIsOver()}
                title={t(msg.titleCountdown)}
              />
            }
            closeText={<Button size='small'>{t(msg.closeTextUpdate)}</Button> }
            closable={true}
            onClose={this.runUpdates}
            afterClose={this.reset}
          />
        </div>
      );
    }

    return null;
  }
  
}

export default Updates = withRouter(injectIntl(Updates));
