import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({
  
  SuccessUnitSystem: {
    id: 'settings.SuccessUnitSystem',
    defaultMessage: 'The unit system has been changed successfully.'
  },

  ErrorUnitSystem: {
    id: 'settings.ErrorUnitSystem',
    defaultMessage: 'There was a problem while changing the unit system.'
  },

  SuccessLanguage: {
    id: 'settings.SuccessLanguage',
    defaultMessage: 'The language has been changed successfully.'
  },

  ErrorLanguage: {
    id: 'settings.ErrorLanguage',
    defaultMessage: 'There was a problem while changing the language.'
  },

  ErrorEmptyPassword: {
    id: 'settings.ErrorEmptyPassword',
    defaultMessage: 'The password field can\'t be empty'
  },

  ErrorEmptyRepeatePassword: {
    id: 'settings.ErrorEmptyRepeatePassword',
    defaultMessage: 'The repeat password field can\'t be empty'
  },

  ErrorPasswordDontMatch: {
    id: 'settings.ErrorPasswordDontMatch',
    defaultMessage: 'The passwords doesn\'t match'
  },

  SuccessPassword: {
    id: 'settings.SuccessPassword',
    defaultMessage: 'The password has been successfully changed'
  },

  ErrorPassword: {
    id: 'settings.ErrorPassword',
    defaultMessage: 'There was an problem while changing password'
  },

  SuccessUnit: {
    id: 'settings.SuccessUnit',
    defaultMessage: 'The unit has been successfully activated'
  },

  ErrorUnit: {
    id: 'settings.ErrorUnit',
    defaultMessage: 'There was an problem while activating new unit'
  },

  previousPassword: {
    id: 'settings.previousPassword',
    defaultMessage: 'Previous password'
  },

  password: {
    id: 'settings.password',
    defaultMessage: 'Password'
  },

  newPassword: {
    id: 'settings.newPassword',
    defaultMessage: 'New password'
  },

  repeatPassword: {
    id: 'settings.repeatPassword',
    defaultMessage: 'Repeat password'
  },

  changePassword: {
    id: 'settings.changePassword',
    defaultMessage: 'Change Password'
  },

  accountSettings: {
    id: 'settings.accountSettings',
    defaultMessage: 'Account settings '
  },

  settings: {
    id: 'settings.settings',
    defaultMessage: 'Settings'
  },

  unitSystem: {
    id: 'settings.unitSystem',
    defaultMessage: 'Unit system'
  },

  emailNotifications: {
    id: 'settings.emailNotifications',
    defaultMessage: 'Email Notifications filter'
  },

  addUnit: {
    id: 'settings.addUnit',
    defaultMessage: 'Add new unit'
  },

  activationKey: {
    id: 'settings.activationKey',
    defaultMessage: 'Activation key'
  },
  
  user: {
    id: 'settings.user',
    defaultMessage: 'User'
  },

  language: {
    id: 'settings.language',
    defaultMessage: 'Language'
  },

  brightness: {
    id: 'settings.brightness',
    defaultMessage: 'Brightness'
  },

  notification: {
    id: 'settings.message',
    defaultMessage: 'Messages'
  },

  delivery: {
    id: 'settings.delivery',
    defaultMessage: 'Delivery'
  },

  request: {
    id: 'settings.request',
    defaultMessage: 'Requests'
  },

  vehicle: {
    id: 'settings.vehicle',
    defaultMessage: 'Vehicles'
  },

  reservation: {
    id: 'settings.reservation',
    defaultMessage: 'Reservations'
  }

})


export default msg