import { defineMessages } from "react-intl";

const msg = defineMessages({

    nothingToShow: {
        id:'messages.nothingToShow',
        defaultMessage:'There is nothing to show'
    },

    all: {
        id:'messages.all',
        defaultMessage:'All'
    },

    unread: {
        id:'messages.unread',
        defaultMessage:'Unread'
    },

    read: {
        id:'messages.read',
        defaultMessage:'Read'
    },

    searchPlaceholder: {
        id:'messages.searchPlaceholder',
        defaultMessage:'Search'
    },

    readToggleMessage: {
        id:'messages.readToggleMessage',
        defaultMessage:'Click on a message to toggle between read or unread!'
    },
})

export default msg