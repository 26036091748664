import React from 'react';
import msg from './messages.js';
import { injectIntl } from "react-intl";
import {
  ScrollMenu,
  VisibilityContext
} from 'react-horizontal-scrolling-menu';

import 'react-horizontal-scrolling-menu/dist/styles.css';

const leftArrow = ()=> {
  const visibility = React.useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible('first', true);
  if (isFirstItemVisible) {
    return null; 
  }
  return (
    <button 
      className="scroll-arrow left-arrow" 
      onClick={() => {const elem = visibility.getPrevElement(); visibility.scrollToItem(elem, 'smooth', 'center');}}
      aria-label="Scroll left"
    >
      <span className="arrow-icon" />
    </button>
  )
}

const rightArrow = ()=> {
  const visibility = React.useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible('last', true);
  if (isLastItemVisible) {
    return null;
  }
  return (
    <button 
      className="scroll-arrow right-arrow"
      onClick={() => {const elem = visibility.getNextElement(); visibility.scrollToItem(elem, 'smooth', 'center');}}
      aria-label="Scroll right"
    >
      <span className="arrow-icon" />
    </button>
  )
}

class MenuHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [],
      selectedCategory: null,
    };
  }

  handleLocalCategoryClick = (category) => {
    this.setState({ selectedCategory: category });
    this.props.handleCategoryClick(category);
  };

  handleTagClick = (tagId) => {
    this.setState((prevState) => {
      const selectedTags = prevState.selectedTags.includes(tagId)
        ? prevState.selectedTags.filter((id) => id !== tagId)
        : [...prevState.selectedTags, tagId];
      return { selectedTags };
    });
    this.props.handleTagClick(tagId);
  };

  render() {
    const { dishes, defaultLang, currentLang, intl, tagsToShow } = this.props;
    const { selectedTags, selectedCategory } = this.state;
    const translate = intl.formatMessage;
    const filterTags = [
      { id: 'veggie', label: translate(msg.vegetarianTag) },
      { id: 'vegan', label: translate(msg.veganTag) },
      { id: 'gluten_free', label: translate(msg.glutenFreeTag) },
    ].filter(tag => tagsToShow.includes(tag.id));

    return (
        <header className="menu-header" id="menu-header">
          <div className="container">
            <div className="scroll-container">
              <ScrollMenu 
                LeftArrow={leftArrow} 
                RightArrow={rightArrow}
              >
                {dishes.map((category) => (
                  <button
                    className={`category-button ${
                      selectedCategory === category.category[defaultLang] ? 'selected' : ''
                    }`}
                    name='category_button'
                    key={category.category[defaultLang]}
                    onClick={() =>
                      this.handleLocalCategoryClick(category.category[defaultLang])
                    }
                  >
                    {category.category[currentLang] ||
                      category.category[defaultLang]}
                  </button>
                ))}
              </ScrollMenu>
            </div>

          <div className="tag-section">
            {filterTags.map((tag) => (
              <button
                className={`tag-button ${
                  selectedTags.includes(tag.id) ? 'selected' : ''
                }`}
                name='tag_button'
                key={tag.id}
                onClick={() => this.handleTagClick(tag.id)}
              >
                {tag.label}
              </button>
            ))}
          </div>
        </div>
      </header>
    );
  }
}

export default injectIntl(MenuHeader);