import React from 'react';
import PageUl from '../../../components/PageUl.js';
import { SmartRequests } from '../../../utilities/index.js';
import MarkdownRenderer from '../../MDRenderer';
import msg from './messages';
import { injectIntl } from "react-intl";
import { Spin } from 'antd';
import { debounce } from 'lodash';
import { reloadIfNeeded, PaginationControl } from '../../../components/Pagination.js';

import { Radio } from 'antd'
import { Input } from 'antd'
const { Search } = Input

function showMessage(msg){
  return (
      <li key= {msg.id} className={msg.status === 0? 'unreaded li-request': 'readed li-request'} onClick={() => this.handleCLick(msg.id, msg.status)}>
        <p className="title">{ msg.subject }</p> 
        <p className="subtitle">{ msg.from_name }</p> 
        <p className="body"><MarkdownRenderer markdown={ msg.body } /></p> 
        <p className="date">{ msg.fmt_date }</p> 
      </li>
  )
}

class Messages extends React.Component{

  constructor(props) {
    super(props); 
    this.state = { 
      searching: false,
      searchResults: null,
      pageOffset: 0,
      newMqttMessage: false,
    };
    this.messages = { results:[] }
    this.query= ''
    this.category= 'unread'
    this.pageSize=10;
    this.debouncedReloadIfNeeded = debounce( reloadIfNeeded.bind( this, 'messages_paginated?', this.setState.bind(this)) , 300)
  }

  updateOffset = (newOffset) => {
    this.setState({pageOffset: newOffset > 0 ? newOffset : 0})
  }

  setSearchQuery(e) {
    this.query = e.target.value
    this.setState({ pageOffset: 0 });
    this.debouncedReloadIfNeeded()
  }

  setSearchCategory(e) {
    this.category = e.target.value
    if (this.category == 'all') {
      this.category = null
    } else if (this.category == 'unread') {
      this.category = '0'
    } else if (this.category == 'read') {
      this.category = '1'
    }
    this.setState({ pageOffset: 0 });
    this.debouncedReloadIfNeeded()
  }

  componentDidMount = () => {
    console.log('Mounting messages')
    this.debouncedReloadIfNeeded()
  }


  handleCLick(msgId, msgStatus){
    let args = 'page_size=' + this.pageSize 
    if (this.query && this.query.length > 0) {
      args += '&q=' + encodeURIComponent(this.query);
    }

    if (this.state.pageOffset && this.state.pageOffset > 0) {
      args += '&offset=' + this.state.pageOffset;
    }

    if (this.category && this.category.length > 0) {
      args += '&state=' + encodeURIComponent(this.category);
    }
    SmartRequests.put('messages_paginated/' + msgId + '?' + args, {seen:(msgStatus === 0 ? 'true':'false' )}).then((response) => {
      if (response.status === 200 && response.data) {
        this.setState({searchResults: response.data}) // Set data from put as search result so it updates
        this.props.toggleMessage(response.data.counter_unread) // toggle message for dashboard widget
      }
    })
  }

  render() {
    let newMqttMessage= false
    if(this.props.data && this.props.data.newMqttMessage && this.props.data.mqttTopicMessage === "messages") {
      this.reload = true
      this.debouncedReloadIfNeeded()
      this.props.methods.pageMessageReceivedCallback()
      newMqttMessage= true
    }
    const translate = this.props.intl.formatMessage
    this.messages= this.props.messages ? this.props.messages : { results:[] }
    if (this.state.searchResults) {
      this.messages = this.state.searchResults
    }

    return (
      <div className='messages'>
        <PageUl>
          <div className="filterBar">
            <Radio.Group 
            defaultValue="unread"
            buttonStyle="solid"
            onChange={this.setSearchCategory.bind(this)}
            >
              <Radio.Button value="unread">{translate(msg.unread)}</Radio.Button>
              <Radio.Button value="all">{translate(msg.all)}</Radio.Button>
            </Radio.Group>
            &nbsp;
            <Search
            placeholder={translate(msg.searchPlaceholder)}
            onChange={this.setSearchQuery.bind(this)}
            style={{ width: 120 }}
            allowClear
            />
          </div>
          <p>{translate(msg.readToggleMessage)}</p>

          <Spin spinning={this.state.searching || this.props.loading} delay={500}>
            {this.messages.total_nof_elements > 0 ? 
            (
              <div>
                <PaginationControl 
                  t={this.props.intl.formatMessage} 
                  pageOffset={this.state.pageOffset} 
                  updateOffset={this.updateOffset} 
                  debouncedReloadIfNeeded={this.debouncedReloadIfNeeded.bind(this)}
                  pageSize={this.pageSize}
                  elements={this.messages}
                />
                {this.messages.results.map(showMessage.bind(this))}
              </div>
            )
              :
            (
              <li className='li-request'> 
                <p> {translate(msg.nothingToShow)} </p> 
              </li>
            )  
            }
          </Spin>
        </PageUl>
      </div>
    );
  }
}

export default  injectIntl(Messages)