export default class CordovaBrightness {
    constructor(sendBrightnessData, set, onBrightness) {
        this.sendBrightnessData = sendBrightnessData;
        this.setBrightness = set;
        onBrightness(this.onBrightness);
    }

    handler = (value) => { 
        this.setBrightness(value);
    }

    onBrightness = (value) => {
        this.sendBrightnessData(value);
    }
}
