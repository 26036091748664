import { defineMessages } from "react-intl";


const msg = defineMessages({

    // General -----------------------
    
    total: {
        id: "restaurant.total",
        defaultMessage: "Total: ${total}",
    },
    
    price: {
        id: "restaurant.price",
        defaultMessage: "Price",
    },

    confirm: {
        id: "restaurant.confirm",
        defaultMessage: "Confirm",
    },

    cancel: {
        id: "restaurant.cancel",
        defaultMessage: "Cancel",
    },

    required: {
        id: "restaurant.required",
        defaultMessage: "Required",
    },

    complete: {
        id: "restaurant.complete",
        defaultMessage: "Complete",
    },

    edit: {
        id: "restaurant.edit",
        defaultMessage: "Edit",
    },

    // -------------------------------
    
    // Main Page ---------------------
    
    mainPageTitle: {
        id: "restaurant.mainPageTitle",
        defaultMessage: "Restaurant Menu",
    },
    
    emptyMenu: {
        id: "restaurant.emptyMenu",
        defaultMessage: "There are currently no dishes in this menu",
    },

    myOrdersButton: {
        id: "restaurant.myOrdersButton",
        defaultMessage: "My Orders",
    },
    
    cartTitle: {
        id: "restaurant.cartTitle",
        defaultMessage: "Your Order",
    },
    
    emptyCart: {
        id: "restaurant.emptyCart",
        defaultMessage: "No dishes in your cart yet",
    },

    hideCart: {
        id: "restaurant.hideCart",
        defaultMessage: "Hide Cart",
    },
    
    showCart: {
        id: "restaurant.showCart",
        defaultMessage: "Show Cart",
    },

    veganTag:{
        id: "restaurant.veganTag",
        defaultMessage: "Vegan"
    },

    vegetarianTag:{
        id: "restaurant.VegetarianTag",
        defaultMessage: "Vegetarian"
    },

    glutenFreeTag:{
        id: "restaurant.GlutenTag",
        defaultMessage: "Gluten Free"
    },

    // -------------------------------

    // Modify Modal ------------------
    
    chooseQuantityOptions: {
        id: "restaurant.chooseQuantityOptions",
        defaultMessage: "Please choose {count} {plural, select, true {options} other {option}}",
    },
    
    chooseQuantityUpTo: {
        id: "restaurant.chooseQuantityUpTo",
        defaultMessage: "Please choose up to {count} {plural, select, true {options} other {option}}",
    },
    
    chooseQuantityAtLeast: {
        id: "restaurant.chooseQuantityAtLeast",
        defaultMessage: "Please choose at least {count} {plural, select, true {options} other {option}}",
    },
    
    chooseQuantityRange: {
        id: "restaurant.chooseQuantityRange",
        defaultMessage: 'Please choose between {min} and {max} options',
    },
    
    modifyModalTitle: {
        id: "restaurant.modifyModalTitle",
        defaultMessage: "Modify your {name}",
    },

    // -------------------------------

    // Confirm Order Modal -----------
    
    confirmOrderButton: {
        id: "restaurant.confirmOrderButton",
        defaultMessage: "Confirm Order",
    },
    
    confirmOrderTitle: {
        id: "restaurant.confirmOrderTitle",
        defaultMessage: "Confirm order",
    },

    deliveryButton: {
        id: "restaurant.deliveryButton",
        defaultMessage: "Deliver in ...",
    },
    dineInButton: {
        id: "restaurant.dineInButton",
        defaultMessage: "Dine-in",
    },
    tableNumber: {
        id: "restaurant.tableNumber",
        defaultMessage: "Table number (optional)",
    },
    dineInNotes: {
        id: "restaurant.dineInNotes",
        defaultMessage: "Any special requests for your dine-in order?",
    },
    deliveryButtonExplain: {
        id: "restaurant.deliveryButtonExplain",
        defaultMessage: "You must select a delivery option within the building",
    },
    dineInButtonExplain:{
        id: "restaurant.dineInButtonExplain",
        defaultMessage: "The order will be delivered to you at {res_name} tables",
    },
    pickupButtonExplain: {
        id: "restaurant.pickupButtonExplain",
        defaultMessage: "The order must be picked up at {res_name}",
    },

    pickupButton: {
        id: "restaurant.pickupButton",
        defaultMessage: "Pick-up",
    },
    
    emptyOrder: {
        id: "restaurant.emptyOrder",
        defaultMessage: "No dishes in your order",
    },

    own_unit: {
        id: "restaurant.own_unit",
        defaultMessage: "Deliver to unit: {unit}",
    },

    selectDeliveryPlace: {
        id: "restaurant.selectDeliveryPlace",
        defaultMessage: "Select a delivery place",
    },

    nested_place: {
        id: "restaurant.nested_place",
        defaultMessage: "Please select a more specific location within {place_name}:",
    },

    pickupNotes: {
        id: "restaurant.pickupNotes",
        defaultMessage: "Add a note for the staff",
    },

    deliveryNotes: {
        id: "restaurant.deliveryNotes",
        defaultMessage: "Add a note to help the staff deliver your order, e.g. Phone number, special instructions, etc.",
    },

    ContinueStep: {
        id: "restaurant.ContinueStep",
        defaultMessage: "Continue to step 2",
    },
    
    restaurantClosed: {
        id: "restaurant.restaurantClosed",
        defaultMessage: "Restaurant is closed",
    },

    // -------------------------------

    // My Orders Page ----------------
    
    myOrdersTitle: {
        id: "restaurant.myOrdersTitle",
        defaultMessage: "My orders",
    },
    
    emptyMyOrders: {
        id: "restaurant.emptyMyOrders",
        defaultMessage: "No orders found matching criteria!",
    },

    deliveredAt: {
        id: "restaurant.deliveredAt",
        defaultMessage: "Delivered at {dateTime}",
    },

    estimatedReceiveTime: {
        id: "restaurant.estimatedReceiveTime",
        defaultMessage: "Estimated time {receiveMethod, select, ready_for_deliver {of delivery} ready_for_pickup {for pickup} other {}}: {time}",
    },

    cancelledNote: {
        id: "restaurant.cancelledNote",
        defaultMessage: "Cancel Note: {cancelNote}"
    },

    createdAt: {
        id: "restaurant.createdAt",
        defaultMessage: "Created at: {fmt_created_at}"
    },

    // -------------------------------

    // Alert Messages ----------------

    menuChangedAlertMessage: {
        id: "restaurant.menuChangedAlertMessage",
        defaultMessage: "The restaurant menu has changed",
    },

    menuChangedAlertDescription: {
        id: "restaurant.menuChangedAlertDescription",
        defaultMessage: "{orderChanged, select, true {Your order may have been changed, please check your order.} other {The dishes in your order did not change, you can continue ordering!}}"
    },

    restaurantClosedTitle: {
        id: "restaurant.restaurantClosedTitle",
        defaultMessage: "{restaurant_name} is closed {all_day, select, true {for the day} indefinitely {until further notice} other {}}",
    },

    restaurantClosedDescription: {
        id: "restaurant.restaurantClosedDescription",
        defaultMessage: "{all_day, select, true {Please come back another day} indefinitely {Please come back later} other {We open at {open_time}}}",
    },

    closesSoonTitle: {
        id: "restaurant.closesSoonTitle",
        defaultMessage: "{restaurant_name} will close soon",
    },

    closesSoonDescription: {
        id: "restaurant.closesSoonDescription",
        defaultMessage: "We close in {minutes} {minutes, select, 1 {minute} other {minutes}}",
    },

    // -------------------------------

    // Dish Variants Modal -----------

    dishVariantsModalTitle: {
        id: "restaurant.dishVariantsModalTitle",
        defaultMessage: "{dishName} Variations",
    },

    addVariationButton: {
        id: "restaurant.addVariationButton",
        defaultMessage: "Add new variation",
    },

    // -------------------------------

    // Order Is Old Modal

    orderIsOldModalTitle: {
        id: "restaurant.orderIsOldModalTitle",
        defaultMessage: "We saved your order from",
    },

    orderIsOldModalDescription: {
        id: "restaurant.orderIsOldModalDescription",
        defaultMessage: "Would you like to continue with that order?",
    },

    // -------------------------------

    // Order Changed Modal -----------

    OrderChangedModalTitle: {
        id: "restaurant.OrderChangedModalTitle",
        defaultMessage: "There was a change in the menu of the restaurant while you were ordering",
    },
    
    TotalPrice: {
        id: "restaurant.TotalPrice",
        defaultMessage: "Total price: ",
    },
    ContinueNext: {
        id: "restaurant.ContinueNext",
        defaultMessage: "Next",
    },
    deliveryAndPickup: {
        id: "restaurant.deliveryAndPickup",
        defaultMessage: "Choose how to receive your order:",
    },
    guestNotLoggedInTitle: {
        id: "restaurant.guestNotLoggedInTitle",
        defaultMessage: "You are not logged in. If you are a user, please \n"
    },
    guestNotLoggedInTitleContinuation: {
        id: "restaurant.guestNotLoggedInTitleLink",
        defaultMessage: ", otherwise continue below:",
    },
    LogInLink: {
        id: "restaurant.guestLogInButton",
        defaultMessage: "Log In"
    },
    guestFormDescription: {
        id: "restaurant.guestFormDescription",
        defaultMessage: "If you are a guest, please provide the following information",
    },
    verificationCodeTitle:{
        id: "restaurant.verificationCodeTitle",
        defaultMessage: "Enter the code sent to your phone",
    },
    noCodeReceived:{
        id: "restaurant.noCodeReceived",
        defaultMessage: "No code received?",
    },
    noCodeReceived2:{
        id: "restaurant.noCodeReceived2",
        defaultMessage: "or",
    },
    retryWithNewPhoneNumber:{
        id: "restaurant.retryWithNewPhoneNumber",
        defaultMessage: "retry with a new phone number",
    },
    validateCode:{
        id: "restaurant.validateCode",
        defaultMessage: "Validate code",
    },
    resendCodeIn:{
        id: "restaurant.resendCodeIn",
        defaultMessage: "Resend again in {time} ",
    },
    resendCode:{
        id: "restaurant.resendCode",
        defaultMessage: "Resend code",
    },
    phoneValidated: {
        id: "restaurant.phoneValidated",
        defaultMessage: "You will receive sms updates of your order at {guestPhone}, If you changed your phone number, please validate your new number ",
    },
    sendSms: {
        id: "restaurant.sendSms",
        defaultMessage: "Send SMS",
    },
    here: {
        id: "restaurant.here",
        defaultMessage: "here",
    },
    namePlaceholder: {
        id: "restaurant.namePlaceholder",
        defaultMessage: "Name",
    },
    unitPlaceholder: {
        id: "restaurant.unitPlaceholder",
        defaultMessage: "Unit number",
    },
    phoneNumberPlaceholder: {
        id: "restaurant.phoneNumberPlaceholder",
        defaultMessage: "Phone number",
    },
    phoneValidationExplanation: {
        id: "restaurant.phoneValidationExplanation",
        defaultMessage: "A sms with a verification code will be sent to this number",
    },
    DishNotAvailable: {
        id: "restaurant.DishNotAvailable",
        defaultMessage: "Dish no longer available",
    },

    OptionsModified: {
        id: "restaurant.OptionsModified",
        defaultMessage: "Options have been modified! (your selections still exist)",
    },

    OptionsDontExist: {
        id: "restaurant.OptionsDontExist",
        defaultMessage: "Some options you had selected don't exist anymore",
    },
    errorCreatingOrder:{
        id: "restaurant.errorCreatingOrder",
        defaultMessage: "Error creating order, please try again"
    },
    ExtrasModified: {
        id: "restaurant.ExtrasModified",
        defaultMessage: "Extras have been modified! (your selections still exist, but prices may have changed)",
    },

    ExtrasDontExist: {
        id: "restaurant.ExtrasDontExist",
        defaultMessage: "Some extras you had selected don't exist anymore",
    },

    MinimumSelectionsChanged: {
        id: "restaurant.MinimumSelectionsChanged",
        defaultMessage: "Minimum number of options has changed",
    },

    MaximumSelectionsChanged: {
        id: "restaurant.MaximumSelectionsChanged",
        defaultMessage: "Maximum number of options has changed",
    },

    veganTag:{
        id: "restaurant.veganTag",
        defaultMessage: "Vegan"
    },
    vegetarianTag:{
        id: "restaurant.VegetarianTag",
        defaultMessage: "Vegetarian"
    },
    glutenFreeTag:{
        id: "restaurant.GlutenTag",
        defaultMessage: "Gluten Free"
    },
    cancelledNote: {
        id: "restaurant.cancelledNote",
        defaultMessage: "Cancel Note: {cancelNote}"
    },
    createdAt: {
        id: "restaurant.createdAt",
        defaultMessage: "Created at: {fmt_created_at}"
    },
    guestFormTitle: {
        id: "restaurant.guestFormTitle", 
        defaultMessage: "Order as a Guest"
    },

    unitInfo: {
        id: "restaurant.unitInfo", 
        defaultMessage: "Unit number of the person you were invited by is required for delivery"
    },

});

export default msg;
