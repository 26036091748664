import CordovaNotificationHandler from './cordovaNotificationHandler';
import CordovaToken from './cordovaToken';
import CordovaInit from './cordovaInit';
import CordovaBrightness from './cordovaBrightness';
import MDMConfig from './cordovaMDMConfig';
import versionValueListener from './cordovaVersion';
import {storageHandler} from '../../../utilities/customStorage';

class cordovaCommunication {
    SET_STATE_ACTION = 'setState';
    DATA_ACTION = 'data';
    CLOSED_STATE = 'closed';
    OPEN_STATE = 'open';
    READY_CHANNEL = 'ready';
    INITIALCONFIG_CHANNEL = 'initialConfig';
    MDMCONFIG_CHANNEL = 'mdmConfig';
    TOKEN_CHANNEL = 'token';
    NOTIFICATIONS_CHANNEL = 'notifications';
    BRIGHTNESS_CHANNEL = 'brightness';
    INTERACTION_CHANNEL = 'interaction';
    DIMMERIZED_SCREEN = 'dimmerizedScreen';
    VERSION = 'version';
    LOGIN = 'login';
    STORAGE = 'storage';
    UPDATING = 'updating';
    LIFECYCLE = 'lifecycle'
    LINK_OPENER = 'linkOpener'
    LINK_FOLLOWER = 'linkFollower'

    ALL_CHANNELS = [
        this.READY_CHANNEL,
        this.INITIALCONFIG_CHANNEL,
        this.MDMCONFIG_CHANNEL,
        this.TOKEN_CHANNEL,
        this.NOTIFICATIONS_CHANNEL,
        this.BRIGHTNESS_CHANNEL,
        this.INTERACTION_CHANNEL,
        this.DIMMERIZED_SCREEN,
        this.VERSION,
        this.LOGIN,
        this.STORAGE,
        this.UPDATING,
        this.LIFECYCLE,
        this.LINK_OPENER,
        this.LINK_FOLLOWER,
    ];

    handlers = {
        [this.READY_CHANNEL]: null,
        [this.INITIALCONFIG_CHANNEL]: null,
        [this.MDMCONFIG_CHANNEL]: null,
        [this.TOKEN_CHANNEL]: null,
        [this.NOTIFICATIONS_CHANNEL]: null,
        [this.BRIGHTNESS_CHANNEL]: null,
        [this.INTERACTION_CHANNEL]: null,
        [this.DIMMERIZED_SCREEN]: null,
        [this.VERSION]: null,
        [this.LOGIN]: null,
        [this.STORAGE]: null,
        [this.UPDATING]: null,
        [this.LIFECYCLE]: null,
        [this.LINK_OPENER]: null,
        [this.LINK_FOLLOWER]: null,
    }

    setBrightnessHandlers = (set, onChange) => {
        onChange(this.sendData.bind(this, this.BRIGHTNESS_CHANNEL));
        this.handlers[this.BRIGHTNESS_CHANNEL] = set;
        this.openChannel(this.BRIGHTNESS_CHANNEL);
        return { close: this.closeChannel.bind(this, this.BRIGHTNESS_CHANNEL) };
    }

    setDimmerizedScreenHandler = (handler) => {
        this.handlers[this.DIMMERIZED_SCREEN] = handler;
        this.openChannel(this.DIMMERIZED_SCREEN);
        return { close: this.closeChannel.bind(this, this.DIMMERIZED_SCREEN) };
    }

    writeMessage = function(data) {}

    initialize = (history, setToken, startFunction, setTpMode) => {
        CordovaNotificationHandler.setHistory(history);
        this.waitForPreferences = CordovaInit.waitForPreferences;
        this.setHandler(this.INITIALCONFIG_CHANNEL, CordovaInit.setData)
        this.setHandler(this.TOKEN_CHANNEL, (new CordovaToken(setToken)).setToken)
        this.setHandler(this.NOTIFICATIONS_CHANNEL, CordovaNotificationHandler.handleNotification)
        this.setHandler(this.MDMCONFIG_CHANNEL, (new MDMConfig(setTpMode)).set)
        this.setHandler(this.VERSION, versionValueListener)
        this.setHandler(this.STORAGE, storageHandler)
        this.prepareMessages();
        this.listen();
        this.notifyInteraction();
        this.openChannel(this.VERSION);
        this.openChannel(this.UPDATING);
    }

    setHandler = (channelName, handler) => {
        if (this.isChannelValid(channelName)) {
            this.handlers[channelName] = handler
        }
    }

    prepareMessages = () => {
        const runningInIframe = window.parent !== window;
        if (runningInIframe) {
            this.writeMessage = data => window.parent.postMessage(data, "*");
        } else {
            console.log('[CordovaCommunication] Not runing in native app, messages disabled');
        }
    }

    listen = () => {
        const listenerOptions = {passive: true, capture: true};
        this.openChannel(this.INTERACTION_CHANNEL);
        window.addEventListener('message', this.messageListener, listenerOptions);
        window.document.addEventListener('touchstart', this.notifyInteraction, listenerOptions);
        window.document.addEventListener('touchmove', this.notifyInteraction, listenerOptions);
        window.document.addEventListener('touchend', this.notifyInteraction, listenerOptions);
        window.addEventListener('input', this.notifyInteraction, listenerOptions);
    }

    notifyInteraction = () => {
        this.sendData(this.INTERACTION_CHANNEL);
    }

    sendData = (channelName, data) => {
        if (this.isChannelValid(channelName)) {
            console.log(`[CordovaCommunication.sendData()] Sending data through channel ${channelName}`);
            this.writeMessage({
                action: this.DATA_ACTION,
                channelName: channelName,
                payload: data,
            });
        } else {
            console.log(`[CordovaCommunication.sendData()]! Could not send message through invalid channel ${channelName}`);
        }
    }

    messageListener = (event) => {
        // console.log(`Message event recieved: ${JSON.stringify(event)}`);
        var data = event.data;
        var channelName = data.channelName;
        var payload = data.payload;
        this.handleAction(channelName, payload);
    }

    handleAction = (channelName, payload) => {
        var channelHandler = this.handlers[channelName];
        if (channelName) console.log(`Looking for "${channelName}" into all registered handlers: ${JSON.stringify(this.handlers)}`)
        if (this.isHandlerReady(channelName)) {
            console.log(`[CordovaCommunication.handleAction()] Handling message on channel "${channelName}": ${JSON.stringify(payload)}`);
            channelHandler(payload);
        } else {
            if (![undefined, null].includes(channelName)) {
                console.log(`[CordovaCommunication.handleAction()]! There is no handler for channel "${channelName}"`);
            }
        }
    }

    closeAllChannels = () => {
        // all except LOGIN and STORAGE, which are always opened
        const allClosableChannels = this.ALL_CHANNELS.filter(channel => ![this.LOGIN, this.STORAGE].includes(channel))
        allClosableChannels.forEach((channelName) => { this.closeChannel(channelName) })
    }

    openChannel = (channelName) => {
        if (this.isChannelValid(channelName)) {
            console.log(`Opening channel ${channelName}`);
            this.writeMessage({
                action: this.SET_STATE_ACTION,
                channelName: channelName,
                newState: this.OPEN_STATE,
            });
        }
    }

    closeChannel = (channelName) => {
        if (this.isChannelValid(channelName)) {
            this.writeMessage({
                action: this.SET_STATE_ACTION,
                channelName: channelName,
                newState: this.CLOSED_STATE,
            });
        }
    }

    isHandlerReady = (channelName) => {
        return this.isChannelValid(channelName) && this.handlers[channelName];
    }

    isChannelValid = (channelName) => {
        const isValid = this.ALL_CHANNELS.includes(channelName);
        if (!isValid && channelName) console.log(`Channel ${channelName} is not valid`);
        return isValid;
    }
}

export const CordovaCommunication = new cordovaCommunication();
export const setBrightnessHandlers = CordovaBrightness.setBrightnessHandlers;
