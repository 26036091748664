import React from 'react';
import { Link } from 'react-router-dom'
import Header from './Header.js'
import { Form, Input, Icon, Button, message } from 'antd';
import * as utils from '../../../utilities/utils.js'
import { injectIntl } from "react-intl";
import msg from './messages'
import { SmartRequests } from '../../../utilities/index.js';

const FormItem = Form.Item

class Reset extends React.PureComponent{
  
  constructor(props) {
    super(props);
    this.state = { activation:'', email:'' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.t = this.props.intl.formatMessage
  }

  handleChange(event) {
    this.setState({[event.target.name]:event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();
    SmartRequests.post('/account/forgot', {ident:this.state.email}).then((response) => {
      response.data.status === "error" ? message.error(this.t(msg.ErrorIncorrectEmail)) : message.success(this.t(msg.SuccessReseted));
    }).catch((error) => {
      message.error(this.t(msg.ErrorIncorrectEmail))
    });
  }

  render(){
    const t = this.t
    return (
      <div  className="reset option-page">
        <div class="back-link">
          <Link to="../login" ><Icon type="arrow-left" /><p>{t(msg.login)}</p></Link>
        </div>
        <Form onSubmit={this.handleSubmit}> 
          <Header>{t(msg.resetPassword)}</Header>
          <FormItem>
            <Input 
              prefix={<Icon type="mail" />}  
              name="email" 
              id="email" 
              placeholder={t(msg.email)} 
              onChange={this.handleChange} 
            />
          </FormItem>
          <Button type="primary" htmlType="submit" >{t(msg.reset)}</Button>
        </Form>
      </div>
    )
  }
}


export default Reset =  injectIntl(Reset)