import React from 'react';
import { Link } from 'react-router-dom'

import { Form, Input, Icon, Button, message } from 'antd';

import Header from './Header.js'

import { injectIntl } from "react-intl";

import msg from './messages'

const FormItem = Form.Item

class PasswordLess extends React.PureComponent{
  
  constructor(props) {
    super(props);
    this.state = { activation:'' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.t = this.props.intl.formatMessage
  }

  handleChange(event) {
    this.setState({[event.target.name]:event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.pLessLogin(this.state.activation, this.props.history)
    .then( response => message.success(this.t(msg.SuccessActivated)) )
    .catch( error =>  message.error(this.t(msg.ErrorActivated)) )
  }

  render() {
    const t = this.t
    return (
      <div  className="activate option-page">
        <div class="back-link">
          <Link to="../login" ><Icon type="arrow-left" /><p>{t(msg.login)}</p></Link>
        </div>
        <Form onSubmit={this.handleSubmit}> 
          <Header> Activate Device </Header>
          <FormItem>
            <Input 
              type="text" 
              name="activation" 
              id="activation" 
              onChange={this.handleChange} 
              placeholder={t(msg.activation)}
              prefix={<Icon type="key"/>}  
            />
          </FormItem>
          <Button type="primary" htmlType="submit" >{t(msg.activate)}</Button>
        </Form>
      </div>
    )
  }
}

export default PasswordLess =  injectIntl(PasswordLess)