import moment from 'moment';
import React from 'react';
import { Icon, Tooltip, Badge } from 'antd';
import RestaurantDelivery from '../../images/restaurant_icons/delivery'
import RestaurantPickup from '../../images/restaurant_icons/pickup'
import RestaurantPending from '../../images/restaurant_icons/pending'


export function get_pages(root, tags, all_pages) {
  return tags[root].map( pId => (
    {
      page: all_pages[pId],
      subPages: tags[all_pages[pId].name]? 
        get_pages(all_pages[pId].name, tags, all_pages) : 
        []
    })
  ).sort((a,b) => a.page.show_order - b.page.show_order )
}

export function insertReservation(reservations, newRes){
    var i = 0
    var resDate = moment(newRes.start_date)
    while (i < reservations.length && moment(reservations[i].start_date) < resDate) { i++ }
    reservations.splice(i, 0, newRes)
}

const restaurant_icons = {
  'pending': (icon_counter_pending) => (
    <Tooltip title="Pending" key='resto_pending'>
      <Badge count={icon_counter_pending} offset={[3,-3]}>
        <RestaurantPending/>
      </Badge>
    </Tooltip>
  ),
  'ready_for_deliver': (icon_counter_ready_d) => (
    <Tooltip title="Ready for Delivery" key='resto_ready_for_deliver'>
      <Badge count={icon_counter_ready_d} offset={[3,-3]}>
        <RestaurantDelivery/>
      </Badge>
    </Tooltip>
  ),
  'ready_for_pickup': (icon_counter_ready_p) => (
    <Tooltip title="Ready for Pickup" key='resto_ready_for_pickup'>
      <Badge count={icon_counter_ready_p} offset={[3,-3 ]}>
        <RestaurantPickup/>
      </Badge>
    </Tooltip>
  ),
}
// ----------------------------------------------- Widget Functions ------------------------------------------------
 
 export const WIDGET_FUNCTIONS = {

  messages:   function(){
    return { 'value': this.state.messages_counter_unread || ''}
  },

  deliveries: function(){
    return { 'value': this.state.deliveries.total_nof_elements || ''}
  },

  valet:      function(){
    return { 'value': this.state.vehicles.reduce((old,car) => old + (car.state === 'ready' | 0), 0) || ''}
  },

  weather:    function(){
    return { 'value': ''}
  },

  polls: function(){
    return { 'value': this.state.polls.unanswered || ''}
  },


  restaurant: function(page_id){
    var icons =[]
    if(this.state.myOrders && this.state.myOrders[page_id]){
      var icon_counter_pending = 0
      var icon_counter_ready_d = 0
      var icon_counter_ready_p = 0
      this.state.myOrders[page_id].results.forEach(order => {
        if( order.state === 'pending' || order.state === 'started'){
          icon_counter_pending++
        } else if (order.state === 'ready_for_deliver' || order.state=='ready_for_dine_in'){
          icon_counter_ready_d++
        } else if (order.state === 'ready_for_pickup'){
          icon_counter_ready_p++
        }
      })
      // show icons for pickup/delivery depending on which has more. If both 0 and pending has more than 0, show pending
      if (icon_counter_ready_p >= icon_counter_ready_d && icon_counter_ready_p > 0
      ) {
        icons.push(restaurant_icons['ready_for_pickup'](icon_counter_ready_p));
      } else if (icon_counter_ready_d>0) {
        icons.push(restaurant_icons['ready_for_deliver'](icon_counter_ready_d));
      } else if (icon_counter_pending > 0) {
        icons.push(restaurant_icons['pending'](icon_counter_pending));
      }
      return { 'icons': icons };
    }
  },
}
