import React from 'react';
import { Modal, Button } from 'antd';

class LoginError extends React.Component {
    render = () => {
        let theStyle = {
            display: this.props.visible ? 'flex' : 'none'
        }
        return (
            <Modal 
                title="Authentication error"
                className="auth-error-component" 
                visible={this.props.visible}
                closable={false}
                maskClosable={true}
                footer={[
                    <Button key="1" type="danger" onClick={this.props.logout}> Ignore </Button>,
                    <Button key="2" type="danger" onClick={this.props.logout}> Log out </Button>
                ]}
                >
                    <p className="message-content">
                        We could not verify your identity.<br />
                        If the problem persists please log out and log in again.
                    </p>
                    <div className="button-holder">
                    </div>
            </Modal>
        );
    }
}

export default LoginError;
