import React from 'react'
import * as utils from '../../utilities/utils.js';
import { SmartRequests } from '../../utilities/index.js';
import { injectIntl } from "react-intl";
import { Form, Icon, Switch, Input, Select, Button, Divider, message, Slider } from 'antd'
import { Link } from 'react-router-dom'
import msg from './messages'
import BrightnessSlider from './BrightnessSlider.js';
import Experimental from './Experimental'
import { ClickHandler } from './Experimental'
import { Version } from './Version.js';
import Header from '../PageView/Header.js';

const Option = Select.Option

const FormItem = Form.Item

const ActivationInput = Input.Search

const LANGUAGE = {
  en: 'English',
  es: 'Español',
  pt: 'Portugues',
  ru: 'Русский'
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 16 },
  },
}

class Settings extends React.Component{

  state = {
    email: "",
    fetching_reset: false,
    reset_state: undefined,
    password:"",
    old_password:"",
    reset:"",
    selected_language:"",
    languages: [['es', 'Spanish'], ['en', 'English']],
    selected_system:"",
    systems:[['us','US (yd/lb/ºF)'], ['metric','Metric (m/kg/ºC)']],
    emails: [],
    brightness: 0,
    email_filters: {}
  }

  componentDidMount = () => {
    this.t = this.props.intl.formatMessage
    SmartRequests.get('info').then( response => {
      console.log(response)
      let building = this.props.building
      this.setState({
        selected_language: response.data.locale,
        selected_system: response.data.unit_system,
        email: response.data.email,
        languages: building? building.locales.split(',').map( lang => [ lang, LANGUAGE[lang] ] ) : [],
        email_filters: response.data.email_filtes
      })
    })
  }

  changeLanguage = input => {
    this.props.block(true)
    SmartRequests.put('user', { locale: input }).then( () => {
      this.props.block(false)
      this.setState({selected_language:input})
      this.props.changeLocale(input)
    })
    .catch( error => message.error(this.t(msg.ErrorLanguage)))
  }

  changeSystem = input => {
    this.props.block(true)
    SmartRequests.put('user', { unit_system: input }).then(() => {
      this.props.block(false)
      this.setState({selected_system:input})
      message.success(this.t(msg.SuccessUnitSystem))
    }).catch( error => message.error(this.t(msg.ErrorUnitSystem)) )
  }

  changeText = input => {
    this.setState({[input.target.name]:input.target.value})
  }

  changeBrightness = value => {
    this.setState({ brightness: value });
  }

  changeFilterOpiton = (name, input) => {
    this.setState( old => {
      let newFilters = Object.assign({}, old.email_filters) 
      newFilters[name] = input
      return {email_filters: newFilters}
    })
    if ( this.filter_timout ){
      clearTimeout(this.filter_timout)
    }
    this.filter_timout = setTimeout(this.send_filters, 1500)
  }

  send_filters = () => {
    clearTimeout(this.filter_timout)
    this.filter_timout = null 
    SmartRequests.post(
      'email_block', {
        disable: Object.keys(this.state.email_filters).filter( key => this.state.email_filters[key] === true),
        enable:  Object.keys(this.state.email_filters).filter( key => this.state.email_filters[key] === false)
      }
    )
  }

  activateUnit = value => {
    this.props.block(true)
    SmartRequests.post('activation', { key:value} ).then( () => {
      this.props.block(false)
      message.success(this.t(msg.SuccessUnit))        
    } ).catch(error => {
      this.props.block(false)
      message.error(this.t(msg.ErrorUnit))  
    })
  }

  changePassword = event => {
    event.preventDefault()
    if (this.state.password === ""){
      message.error(this.t(msg.ErrorEmptyPassword))
      return
    }
    if (this.state.reset === ""){
      message.error(this.t(msg.ErrorEmptyRepeatePassword))
      return
    }
    if (this.state.password !== this.state.reset){
      message.error(this.t(msg.ErrorPasswordDontMatch))
      return
    }
    this.props.block(true)
    SmartRequests.post(
      '/account/password', 
      {
        ident:this.state.email,
        oldpass:this.state.old_password,
        newpass:this.state.password,
        newpass2:this.state.reset
      }
    ).then(() => {
      this.setState({
        oldpass: '',
        newpass: '',
        newpass2: ''
      })
      this.props.block(false)
      message.success(this.t(msg.SuccessPassword))
    }).catch(error => {
      this.props.block(false)
      message.error(this.t(msg.ErrorPassword))
    })
  }

  render(){
    const t = this.props.intl.formatMessage
    return (
      <div className="settings"> 
        <Header> {t(msg.settings)} </Header>
        <div className="frame">
          <h1  className="unselectable">{t(msg.accountSettings)} <Icon type="tool" /> </h1>
          <div className="setting-option">
            <p>{t(msg.language)}:</p>   
            <Select value={this.state.selected_language} onChange={this.changeLanguage} >
              {this.state.languages.map( el => <Option value={el[0]}>{el[1]}</Option>)}
            </Select>
          </div>
          <div className="setting-option">
            <p>{t(msg.unitSystem)}:</p>    
            <Select value={this.state.selected_system} onChange={this.changeSystem} >
              {this.state.systems.map( el => <Option value={el[0]}>{el[1]}</Option>)}
            </Select>
          </div>
          <Divider />
          { ( !this.props.isDevice ) ?
            <Form className="change-password" onSubmit={this.changePassword}>
              <div className="mail"><p>{t(msg.user)}: </p><p className="own-email">{this.state.email}</p></div>
              <FormItem label={t(msg.previousPassword)}  {...formItemLayout}>
                <Input 
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  
                  placeholder={t(msg.previousPassword)} 
                  name="old_password" 
                  type="password"
                  value={this.state.old_password} 
                  onChange={this.changeText}
                  disabled={this.state.fetching_reset}
                />
              </FormItem>
              <FormItem label={t(msg.newPassword)} {...formItemLayout}>
                <Input 
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  
                  placeholder={t(msg.newPassword)} 
                  name="password" 
                  type="password"
                  value={this.state.password} 
                  onChange={this.changeText}
                  disabled={this.state.fetching_reset}
                />
              </FormItem>
              <FormItem label={t(msg.repeatPassword)} {...formItemLayout}>
                <Input 
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  
                  placeholder={t(msg.repeatPassword)}
                  name="reset" 
                  type="password" 
                  value={this.state.reset}
                  onChange={this.changeText}
                  disabled={this.state.fetching_reset}
                />
              </FormItem>
              <div className="submit-box">
                <Button htmlType="submit" disabled={this.state.fetching_reset} loading={this.state.fetching_reset} >{t(msg.changePassword)}</Button>
              </div>
            </Form> : ''
          }
        </div>

        { ( !this.props.isDevice ) ?
          <div className="frame">
            <h1>{t(msg.emailNotifications)}  <Icon type="notification" /> </h1>
              <div className="notifications">
                {Object.keys(this.state.email_filters).map( el => 
                  <div className="notification-switch">
                    <p>{t(msg[el])}</p>
                    <Switch defaultChecked size="small" checked={this.state.email_filters[el]} onChange={this.changeFilterOpiton.bind(this, el)} />
                  </div>
                )}
              </div>
          </div> : ''
        }
        { ( ! this.props.tpMode ) ?
          <div className="frame">
            <h1>{t(msg.addUnit)} <Icon type="home" /> </h1>
             <ActivationInput
                prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder={t(msg.activationKey)}
                enterButton={<Icon type="plus" />}
                size="large"
                onSearch={this.activateUnit}
              />
          </div> : ''
        }
        { ( this.props.tpMode ) ?
          <div className="frame">
            <BrightnessSlider />
          </div> : ''
          }
        <Experimental />
        <Divider></Divider>
        <ClickHandler targetCount={5} timeout={300} >
          <Version />
        </ClickHandler>
      </div>
    )
  }
}

export default Settings = injectIntl(Settings)
